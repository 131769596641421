import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import axios from "axios";
import { store } from "../../../Store";
import CheckBeforeRequest from "../CheckBeforeRequest";
import GetCookie from "../Functions/GetCookie";
import { SetUserAndConnection, SetUserPermissions } from "../../../Actions/User";

export function withProfile<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>): React.FC<P> {
    const Result = (props: React.ComponentProps<typeof Component>) => {
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();
        const [finishedRequest, setFinishedRequest] = useState(false);
        const location = useLocation();

        useEffect(() => {
            (async () => {
                const { headers } = CheckBeforeRequest();
                try {
                    let token = GetCookie("token");
                    let id_user = GetCookie("id_user");
                    let client_user = GetCookie("client_user");
                    let domain_name = window.location.host.replace("www.", "");
                    if (store.getState().user.user === null && token !== undefined && token !== null && token !== "") {
                        const response = await axios.get(
                            `${API_HREF}client/${client_user}/user/${id_user}/`,
                            { headers }
                        );
                        const data = response.data;

                        console.log(data);
                        if (JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === "amerigo" && !data.is_superuser) {
                            enqueueSnackbar(
                                "FacilitaTrip est forcée d'interrompre le service Free Independent Traveler pour le compte du tour-opérateur Amerigo. Nous en sommes sincèrement désolés, mais nous poursuivons l'aventure avec d'autres partenaires",
                                { variant: "error", anchorOrigin: { horizontal: 'center', vertical: 'top' } }
                            );
                        } else if (JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === "pbe" && !data.is_superuser) {
                            enqueueSnackbar(
                                "FacilitaTrip est forcée d'interrompre le service Free Independent Traveler pour le compte du tour-opérateur Plus Belle l'Europe. Nous en sommes sincèrement désolés, mais nous poursuivons l'aventure avec d'autres partenaires",
                                { variant: "error", anchorOrigin: { horizontal: 'center', vertical: 'top' } }
                            );
                        } else {
                            dispatch(SetUserAndConnection(data, true));
                            if (!!data.lang && data.lang.split("-").length > 0) {
                                dispatch({ type: 'CART_SET_QUOTATION_TARGET_LANGUAGE', payload: data.lang.split("-")[0] })
                            }
                            if (data.is_superuser === false) {
                                const response = await axios.get(
                                    `${API_HREF}client/${client_user}/user/${id_user}/permissions/?all=true`,
                                    { headers }
                                );
                                const data_perm = response.data;
                                console.log('data_perm:', data_perm);
                                dispatch(SetUserPermissions(data_perm));
                            } else {
                                const response = await axios.get(
                                    `${API_HREF}permissions/?all=true`,
                                    { headers }
                                );
                                const data_perm = response.data;
                                console.log('data_perm:', data_perm);
                                dispatch(SetUserPermissions(data_perm));
                            }
                            if (client_user !== undefined && client_user !== null && client_user !== "" && location.pathname === "/home") {
                                window.location.href = `/home/dev`;
                                // if (window.location.host === "localhost:3000") {
                                //     window.location = `/home/dev`;
                                // } else if (data.client_full.type !== 2) {
                                //     window.location = `/home/${domain_name.split('.')[0]}`;
                                // } else {
                                //     window.location = `/home/${client_user}`;
                                // }
                            }
                        }
                    } else if (client_user !== undefined && client_user !== null && client_user !== "" && location.pathname === "/home") {
                        window.location.href = `/home/dev`;
                        // if (window.location.host === "localhost:3000") {
                        //     window.location = `/home/dev`;
                        // } else if (store.getState().user.user?.client_full.type !== 2) {
                        //     window.location = `/home/${domain_name.split('.')[0]}`;
                        // } else {
                        //     window.location = `/home/${client_user}`;
                        // }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setFinishedRequest(true);
                }
            })();
        }, []);

        if (!finishedRequest) {
            return null;
        }

        return (
            <Component {...props} />
        );
    }

    Result.displayName = `withProfile(${Component.displayName ?? Component.name})`;

    return Result;
}