import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { AppState } from "../../Reducers/Reducers";

export function CheckFlightRoute(props: PropsWithChildren): React.ReactNode {
    const { url_name } = useParams();
    const indexGroup = useSelector((state: AppState) => state.flight.index_group);
    const copyIndexGroup = useSelector((state: AppState) => state.flight.copy_index_group);

    if (indexGroup === null || copyIndexGroup === null) {
        return (
            <Navigate
                        to={`/${url_name}/apps/flight-groups`}
                        replace
                    />
        );
    }

    return props.children;
}