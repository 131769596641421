import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { AppState } from "../../Reducers/Reducers";

export function CheckDepartureReturnRoute(props: PropsWithChildren): React.ReactNode {
    const { url_name } = useParams();
    const departureCity = useSelector((state: AppState) => state.itinerary.departure_destination.city);
    const returnCity = useSelector((state: AppState) => state.itinerary.return_destination.city);

    if (!departureCity && !returnCity) {
        return (
            <Navigate
                to={`/${url_name}/apps/itinerary`}
                replace
            />
        );
    }

    return props.children;
}