import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorColorPicker } from "./mailTemplateVisualEditorColorPicker";

type Props = {
    title?: string,
    color: string,
    onChangeColor: (value: string) => void
}

export function MailTemplateVisualEditorColorOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>
                {props.title ?? t<string>('shared.mail-template-visual-editor-color')}
            </Typography>
            <MailTemplateVisualEditorColorPicker
                color={props.color}
                onChangeColor={props.onChangeColor}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
