import React, { useRef, useState, type JSX } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { EditorState, Modifier } from "draft-js";
import { values } from "lodash";
import { toggleInlineStyle } from "./toggleInlineStyle";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

type Props = {
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
}

export function RichEditorFontSizeControl(props: Props): JSX.Element {
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement>(null);
    const currentStyle = props.editorState.getCurrentInlineStyle();
    const currentFontSize: keyof typeof RichEditorFontSizeStylesMap | undefined = currentStyle.findLast((item) => {
        return item !== undefined && /^FONTSIZE/.test(item);
    }) as keyof typeof RichEditorFontSizeStylesMap;

    const onToggle = () => {
        setOpen((state) => !state);
    };
    const onClose = () => {
        setOpen(false);
    };

    const onChangeFontSize = (fontSize: number) => {
        //get all FONTSIZE styles
        const styles = currentStyle.filter((value) => Boolean(value?.includes('FONTSIZE_')));
        //remove them from current selection
        const content = styles.reduce((content, value) => {
            if (!content || !value) {
                return props.editorState.getCurrentContent();
            }
            return Modifier.removeInlineStyle(
                content,
                props.editorState.getSelection(),
                value
            );
        }, props.editorState.getCurrentContent());
        let newState = EditorState.push(
            props.editorState,
            content,
            'change-inline-style'
        );
        //add new font size style to current selection
        newState = toggleInlineStyle<keyof typeof RichEditorFontSizeStylesMap>(
            newState,
            `FONTSIZE_${fontSize}`
        );
        props.setEditorState(newState);
        onClose();
    };

    return (
        <div>
            <Button
                ref={anchor}
                onMouseDown={(event) => {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                }}
                onClick={onToggle}
                color="inherit"
                endIcon={ open ? <KeyboardArrowUp/> : <KeyboardArrowDown/> }
            >
                {
                    currentFontSize ?
                        RichEditorFontSizeStylesMap[currentFontSize]?.fontSize :
                        RichEditorFontSizeStylesMap.FONTSIZE_16?.fontSize
                }
            </Button>
            <Menu
                anchorEl={anchor.current}
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{ maxHeight: 250 }}
                autoFocus={false}
                disableAutoFocus
                disableAutoFocusItem
                disableEnforceFocus
            >
                {
                    values(RichEditorFontSizeStylesMap).map((item) => (
                        <MenuItem
                            key={item.fontSize}
                            onMouseDown={(event) => {
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                                onChangeFontSize(item.fontSize);
                            }}
                            selected={item.fontSize === 16}
                        >
                            {item.fontSize}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}

export const RichEditorFontSizeStylesMap: {
    [index:`FONTSIZE_${number}`]: {
        fontSize: number
    }
} = {
    FONTSIZE_8: {
        fontSize: 8
    },
    FONTSIZE_9: {
        fontSize: 9
    },
    FONTSIZE_10: {
        fontSize: 10
    },
    FONTSIZE_11: {
        fontSize: 11
    },
    FONTSIZE_12: {
        fontSize: 12
    },
    FONTSIZE_14: {
        fontSize: 14
    },
    FONTSIZE_16: {
        fontSize: 16
    },
    FONTSIZE_18: {
        fontSize: 18
    },
    FONTSIZE_24: {
        fontSize: 24
    },
    FONTSIZE_30: {
        fontSize: 30
    },
    FONTSIZE_36: {
        fontSize: 36
    },
    FONTSIZE_48: {
        fontSize: 48
    },
    FONTSIZE_60: {
        fontSize: 60
    },
    FONTSIZE_72: {
        fontSize: 72
    },
    FONTSIZE_96: {
        fontSize: 96
    }
};
