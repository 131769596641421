import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonGroup, IconButton, Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

export type VerticalAlignment = 'top' | 'middle' | 'bottom'

type Props = {
    alignment: VerticalAlignment,
    onChangeAlignment: (alignment: VerticalAlignment) => void
}

export function MailTemplateVisualEditorVerticalAlignmentOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        (<MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-vertical-alignment')}</Typography>
            <Box>
                <ButtonGroup variant="contained" sx={{ padding: 1 }}>
                    <IconButton
                        sx={{ backgroundColor: props.alignment === 'top' ? '#ccc' : 'inherit' }}
                        onClick={() => props.onChangeAlignment('top')}
                        size="large">
                        <VerticalAlignTopIcon />
                    </IconButton>
                    <IconButton
                        sx={{ backgroundColor: props.alignment === 'middle' ? '#ccc' : 'inherit' }}
                        onClick={() => props.onChangeAlignment('middle')}
                        size="large">
                        <VerticalAlignCenterIcon />
                    </IconButton>
                    <IconButton
                        sx={{ backgroundColor: props.alignment === 'bottom' ? '#ccc' : 'inherit' }}
                        onClick={() => props.onChangeAlignment('bottom')}
                        size="large">
                        <VerticalAlignBottomIcon />
                    </IconButton>
                </ButtonGroup>
            </Box>
        </MailTemplateVisualEditorOptionsSection>)
    );
}
