import React, { PropsWithChildren } from "react";
import { Route, Navigate, RouteProps } from "react-router";
import GetCookie from "./Functions/GetCookie";

const identifier = window.location.host.replace("www.", "").split('.')[0];

export function RequireAuthRoute(props: PropsWithChildren): React.ReactNode {
    let isGuest = false;
    let token = GetCookie("token");
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    if (
        !token &&
        quotationCode === 'beachcomber'
    ) {
        isGuest = true;
    }

    if (isGuest) {
        return (
            <Navigate
                to="/home"
                replace
            />
        );
    }

    return props.children;
}