import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, collapseClasses, styled } from "@mui/material";
import { useDrop } from "react-dnd";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import { RegisteredBlocks } from "./utils/registered-blocks";
import { DraggedItemData } from "./utils/block-features";

export type MailTemplateVisualEditorDropTargetProps = {
    rejectedBlockTypes?: string[],
    targetName: string,
    forceShow?: boolean,
    size?: Size,
    index: number,
    onNewBlock: (type: string | symbol) => void,
    onBlockReorder: (
        options: DraggedItemData & DropResult & {index: number}
    ) => void
}

export type DropResult = {
    targetName: string
}

type CollectedProps = {
    isOver: boolean,
    canDrop: boolean
}

type Size = 'medium' | 'big';

export function MailTemplateVisualEditorDropTarget(
    props: MailTemplateVisualEditorDropTargetProps
): JSX.Element | null {
    const { t } = useTranslation();
    const [collected, drop] = useDrop<DraggedItemData, unknown, CollectedProps>(() => ({
        accept: RegisteredBlocks.filter((block) => {
            return !props.rejectedBlockTypes?.includes(block.type);
        }).map((block) => block.type),
        collect: (monitor) => {
            let canDrop = false;

            //@TODO: find the cause of this error when using multiple instances
            //@see https://github.com/react-dnd/react-dnd/issues/361
            try {
                canDrop = monitor.canDrop();
            } catch (error: any) {
                if (!(error as Error).message.includes('Expected to find a valid target.')) {
                    throw error;
                }
            }

            return {
                isOver: monitor.isOver(),
                canDrop
            };
        },
        drop: (item, monitor): DropResult => {
            if (item.id === undefined) {
                const type = monitor.getItemType();
                if (type) {
                    props.onNewBlock(type);
                }
            } else {
                props.onBlockReorder({
                    ...item,
                    targetName: props.targetName,
                    index: props.index
                });
            }
            return { targetName: props.targetName };
        }
    }), [
        props.index,
        props.targetName,
        props.onNewBlock,
        props.onBlockReorder
    ]);

    return (
        <MailTemplateVisualEditorContext.Consumer>
            {
                (context) => (
                    <Transition
                        orientation="vertical"
                        in={collected.canDrop || props.forceShow}
                        timeout={1000}
                    >
                        <Container
                            ref={drop}
                            size={props.size ?? 'medium'}
                            canDrop={collected.canDrop}
                            isOver={collected.isOver}
                        >
                            {
                                context.placeholder ??
                                t<string>(
                                    collected.isOver ?
                                        'shared.mail-template-visual-editor-release-block' :
                                        'shared.mail-template-visual-editor-drag-block'
                                )
                            }
                        </Container>
                    </Transition>
                )
            }
        </MailTemplateVisualEditorContext.Consumer>
    );
}

const Transition = styled(Collapse)((props) => ({
    [`&.${collapseClasses.entered}, &.${collapseClasses.entered} .${collapseClasses.wrapper}, &.${collapseClasses.entered} .${collapseClasses.wrapper} .${collapseClasses.wrapperInner}`]: {
        height: props.in ? '100% !important' : 'auto'
    }
}));

const Container = styled(
    'div',
    {
        shouldForwardProp(propName) {
            return propName !== 'size' &&
                propName !== 'canDrop' &&
                propName !== 'isOver';
        }
    }
)<{
    size: Size,
    canDrop: boolean,
    isOver: boolean
}>((props) => ({
    width: '100%',
    minHeight: props.size === "big" ?
        '150px' :
        '50px',
    height: '100%',
    backgroundColor:
        //eslint-disable-next-line no-nested-ternary
        props.isOver ? 'rgba(224, 146, 11, 0.5)' :
        //eslint-disable-next-line indent
        props.canDrop ? 'rgba(0, 217, 0, 0.2)' :
            //eslint-disable-next-line indent
            '#D9D9D9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.4)',
    margin: 'auto',
    padding: props.theme.spacing(2),
    border: '1px #292929 dashed',
    borderRadius: props.theme.shape.borderRadius
}));
