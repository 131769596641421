import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Box, Collapse, IconButton, Stack, styled, Tooltip, Typography } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { useDrag } from "react-dnd";
import { MailTemplateVisualEditorContext } from "../mailTemplateVisualEditorContext";
import { RegisterBlockOptions } from "./registered-blocks";
import { AppState } from "../../../../../Reducers/Reducers";
import { selectVisualEditorBlock, setVisualEditorDraggedBlockId, unselectVisualEditorBlock } from "../redux/actions";
import { DropResult } from "../mailTemplateVisualEditorDropTarget";
import DragIcon from "@mui/icons-material/OpenWith";
import DuplicateIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";

export type BlockFeaturedComponentProps<T> = {
    id: number,
    sourceName: string,
    onDuplicate: (id: number) => void,
    onDelete: (id: number) => void,
    onDropped?: (item: DraggedItemData, dropResult: DropResult | null) => void,
    options: T,
}

export type DraggedItemData = {
    id: number,
    sourceInstanceId: string,
    sourceName: string
}

export type ComponentWithBlockFeatures<T> = React.FunctionComponent<BlockFeaturedComponentProps<T>> | React.ComponentClass<BlockFeaturedComponentProps<T>>

export function withBlockFeatures<T>(blockOptions: RegisterBlockOptions<T>): ComponentWithBlockFeatures<T> {
    const Content = (props: BlockFeaturedComponentProps<T>) => {
        const { id, options } = props;
        const context = useContext(MailTemplateVisualEditorContext);
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [collected, drag, dragPreview] = useDrag<DraggedItemData, DropResult, {isDragged: boolean}>(() => ({
            type: blockOptions.type,
            item: {
                id,
                sourceInstanceId: context.instanceId,
                sourceName: props.sourceName
            },
            collect: (monitor) => ({
                isDragged: monitor.isDragging()
            }),
            end: (item, monitor) => {
                if (monitor.didDrop() && props.onDropped) {
                    props.onDropped(item, monitor.getDropResult());
                }
            }
        }), [props.onDropped]);
        const [hover, setHover] = useState(false);
        const selectedBlockId = useSelector((state: AppState) => {
            return state.mailTemplate.others.instances[context.instanceId]?.visualEditorSelectedBlockId;
        });
        const draggedBlockId = useSelector((state: AppState) => {
            return state.mailTemplate.others.instances[context.instanceId]?.visualEditorDraggedBlockId;
        });
        const onSelectBlock = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            dispatch(selectVisualEditorBlock(context.instanceId, id));
        };
        const onDeleteBlock = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            props.onDelete(props.id);
            dispatch(unselectVisualEditorBlock(context.instanceId));
        };
        const shouldHighlight = (
            !context.disableBlocksEdit &&
            (
                (
                    hover &&
                    draggedBlockId === null &&
                    !context.disableHoverInteraction
                ) ||
                selectedBlockId === id
            )
        ) ||
        !!context.forceSelectedState;

        useEffect(() => {
            if (collected.isDragged) {
                dispatch(setVisualEditorDraggedBlockId(context.instanceId, id));
            } else {
                dispatch(setVisualEditorDraggedBlockId(context.instanceId, null));
            }
        }, [collected.isDragged]);

        return (
            (<div
                ref={dragPreview}
                onMouseEnter={() => !context.disableBlocksEdit && setHover(true)}
                onMouseLeave={() => !context.disableBlocksEdit && setHover(false)}
                onMouseDown={onSelectBlock}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="flex-start"
                >
                    {
                        context.enableLeftDrag &&
                        <IconButton
                            ref={drag}
                            sx={{
                                marginTop: 0.75,
                                padding: 0,
                                paddingRight: 0.75,
                                marginLeft: 0.5,
                                cursor: 'move'
                            }}
                            disableRipple
                            size="large">
                            <DragIndicator />
                        </IconButton>
                    }
                    <Box
                        sx={{
                            flex: 1,
                            maxWidth: !context.enableLeftDrag ?
                                '100%' :
                                'calc(100% - 38px)'
                        }}
                    >
                        <Collapse orientation="vertical" in={shouldHighlight} easing="ease-in">
                            <Controls>
                                <LeftControls>
                                    {
                                        !context.enableLeftDrag &&
                                        <Tooltip title={t<string>('shared.mail-template-visual-editor-move')} placement="top">
                                            <ControlButton
                                                ref={drag}
                                                size="small"
                                            >
                                                <DragIcon fontSize="small" />
                                            </ControlButton>
                                        </Tooltip>
                                    }
                                    <Typography>
                                        {t<string>(blockOptions.label)}
                                    </Typography>
                                    {
                                        (() => {
                                            const Component = context.customActions?.[blockOptions.type];
                                            if (Component) {
                                                return (
                                                    <Box sx={{ marginLeft: 1 }}>
                                                        <Component instanceId={context.instanceId} blockId={id} />
                                                    </Box>
                                                );
                                            }
                                        })()
                                    }
                                </LeftControls>
                                <div>
                                    <Tooltip title={t<string>('shared.mail-template-visual-editor-duplicate')} placement="top">
                                        <ControlButton size="small" onMouseDown={() => props.onDuplicate(props.id)}>
                                            <DuplicateIcon fontSize="small" />
                                        </ControlButton>
                                    </Tooltip>
                                    <Tooltip title={t<string>('shared.mail-template-visual-editor-delete')} placement="top">
                                        <ControlButton
                                            size="small"
                                            onMouseDown={onDeleteBlock}>
                                            <DeleteIcon fontSize="small" />
                                        </ControlButton>
                                    </Tooltip>
                                </div>
                            </Controls>
                        </Collapse>
                        <ContentContainer
                            highlight={shouldHighlight}
                            onMouseDown={() => {
                                const handler = context.onContentClick?.[blockOptions.type];
                                if (handler) {
                                    handler({
                                        isBlockSelected: shouldHighlight,
                                        instanceId: context.instanceId,
                                        blockId: id,
                                        blockOptions: props.options
                                    });
                                }
                            }}
                        >
                            <blockOptions.component id={id} options={options} />
                        </ContentContainer>
                    </Box>
                </Stack>
            </div>)
        );
    };
    Content.displayName = `WithBlockFeatures(${blockOptions.component.name})`;
    return Content;
}

const Controls = styled('div')((props) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: props.theme.spacing(1),
    paddingBottom: props.theme.spacing(1),
    paddingLeft: props.theme.spacing(2),
    paddingRight: props.theme.spacing(2),
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.primary.contrastText
}));

const LeftControls = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const ControlButton = styled(IconButton)((props) => ({
    color: props.theme.palette.primary.contrastText
}));

const ContentContainer = styled(
    'div',
    {
        shouldForwardProp(prop) {
            return prop !== 'highlight';
        }
    }
)<{highlight: boolean}>((props) => ({
    border: props.highlight ?
        `2px solid ${props.theme.palette.primary.main}` :
        undefined,
    cursor: 'pointer',
    transition: 'border-color 0.5s'
}));
