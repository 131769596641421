//---- Dependencies ----//
import '@ant-design/v5-patch-for-react-19';
import "./axiosInterceptors";
import React, { Suspense } from 'react';
import { Provider } from "react-redux";
import {
    Route,
    Navigate,
    Outlet,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider
} from "react-router"
import { I18nextProvider } from "react-i18next";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import CssBaseline from '@mui/material/CssBaseline';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import i18n from "./i18n";
import { store } from "./Store";

//---- Actions ----//

//---- Commons ----//
import CloseSnackbar from "./Components/Common/CloseSnackbar";

//---- CSS ----//
import "./Icons/styles.css";
import "./NewIcons/styles.css";
import "./Style/Global.css";
import 'react-virtualized/styles.css';
import "./Components/Menu/MaterialTripList/MailVisualEditor/css/index.css";

//---- Components ----//
import { RequireAuthRoute } from "./Components/Common/requireAuthRoute";
import { CheckDepartureReturnRoute } from "./Components/Common/checkDepartureReturnRoute";
import { CheckFlightRoute } from "./Components/Common/checkFlightRoute";
import { ThemeProvider } from "./Components/Menu/Settings/theme/themeProvider";
import { withProfile } from "./Components/Common/hoc/withProfile";
import { withTrip } from "./Components/Common/hoc/withTrip";
import { withOnEnter } from "./Components/pages/hoc/withOnEnter";
import { withOnLeave } from "./Components/pages/hoc/withOnLeave";

import { resetCircuit } from "./Components/pages/redux/circuitSlice";
import { setSeeProductsMargins } from "./Components/CartMaterial/redux/cartConstructionReducer";

const identifier = window.location.host.replace("www.", "").split('.')[0];

//---- Base -> Header - Component ----//
const Base = React.lazy(() =>
    import("./Components/Base/Base")
);

//---- Itinerary ----//
const Itinerary = React.lazy(() =>
    import("./Components/Itinerary/Itinerary")
);

//---- PassengerGroup ----//
const FlightGroups = React.lazy(() =>
    import("./Components/FlightMaterial/FlightGroups/FlightGroups")
);

//---- FlightSearch ----//
const FlightSearch = React.lazy(() =>
    import("./Components/FlightMaterial/FlightSearch/FlightSearch")
);

//---- SelectedFlight ----//
const SelectedFlight = React.lazy(() =>
    import("./Components/FlightMaterial/FlightSelected/FlightSelected")
);

//---- Accommodation ----//
const AccommodationList = React.lazy(() =>
    import("./Components/Accommodation/AccommodationList")
);
const MaterialAccommodationCard = React.lazy(() =>
    import("./Components/Accommodation/MaterialAccommodationCard")
);

//---- Poi ----//
const PoiList = React.lazy(() =>
    import("./Components/PoiMaterial/PoiList")
);
const PoiCard = React.lazy(() =>
    import("./Components/PoiMaterial/PoiCard")
);

//---- Cars ----//
const CarsSearch = React.lazy(() =>
    import("./Components/Cars/CarsSearch/CarsSearch")
);
//---- MaterialCars ----//
const MaterialCarsSearch = React.lazy(() =>
    import("./Components/CarsMaterial/MaterialCarsSearch")
);
const CarsSearchOneStep = React.lazy(() =>
    import("./Components/Cars/CarsSearch/CarsSearchOneStep")
);
const CarsStep = React.lazy(() =>
    import("./Components/Cars/CarsSearch/CarsStep")
);
const CarsResults = React.lazy(() =>
    import("./Components/Cars/CarsSearch/CarsResults")
);
const CarsAddToCart = React.lazy(() =>
    import("./Components/Cars/CarsSearch/CarsAddToCart")
);

//---- Cart ----//
const Cart = React.lazy(() =>
    import("./Components/CartMaterial/Cart")
);
const CartQuotation = React.lazy(() => {
    import("./Components/CartMaterial/CartQuotation");
});
//---- Booking ----//
const Booking = React.lazy(() =>
    import("./Components/BookingMaterial/Booking")
);
//---- Summary-Quotation ----//
const SummaryQuotation = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryQuotation")
);
const SummaryPayment = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryPayment")
);
const SummaryPaymentSuccess = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryPaymentSuccess")
);
const SummaryPaymentError = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryPaymentError")
);
const SummaryDocuments = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryDocuments")
);
const SummaryInscription = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryInscription")
);
const SummaryContact = React.lazy(() =>
    import("./Components/MaterialSummaryQuotation/SummaryContact")
);

//---- Provider-Website ----//
const ProviderWebsite = React.lazy(() =>
    import("./Components/ProviderWebsite/MaterialProviderWebsite")
);
const ProviderQuotation = React.lazy(() =>
    import("./Components/ProviderQuotation/ProviderQuotation")
);
const ProviderBooking = React.lazy(() =>
    import("./Components/ProviderBooking/ProviderBooking")
);
//---- PCancel-Website ----//
const ProviderCancel = React.lazy(() =>
    import("./Components/ProviderWebsite/ProviderCancel")
);

//---- Home ----//
const Home = React.lazy(() =>
    import("./Components/pages/home")
);
//---- Home visiteurs ----//
const VisiteursHome = React.lazy(() =>
    import("./Components/VisiteursHome/VisiteursHome")
);
//---- ItineraryType & others products ----//
const DestinationProducts = React.lazy(() =>
    import("./Components/pages/destination")
);
const NoDestinationProducts = React.lazy(() =>
    import("./Components/pages/noDestination")
);
//---- ItineraryTypeDetails & others products ----//
const ItineraryTypeDetails = React.lazy(() =>
    import("./Components/pages/itineraryType")
);
const PackagesDetails = React.lazy(() =>
    import("./Components/ItineraryType/PackagesMaterial/PackagesDetails")
);
const CircuitsDetails = React.lazy(() =>
    import("./Components/pages/circuit")
);

//---- Menu ----//
//---- BaseMenu ----//
const BaseMenu = React.lazy(() =>
    //import("./Components/Menu/BaseMenu/BaseMenu")
    import("./Components/Menu/BaseMenu/MaterialBaseMenu")
);
//---- Profile ----//
const Profile = React.lazy(() =>
    import("./Components/Menu/Profile/MaterialProfile")
    //import("./Components/Menu/Profile/Profile")
);

//---- Agency Profile ----//
const AgencyProfile = React.lazy(() =>
    import("./Components/Menu/AgencyProfile/MaterialAgencyProfile")
);

const SalesPersons = React.lazy(() =>
    import("./Components/Menu/SalesPersons/salesPersons")
);

const ThemeSettings = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettings")
);

const ThemeSettingsColors = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettingsColors")
);

const ThemeSettingsInputs = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettingsInputs")
);

const ThemeSettingsTypography = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettingsTypography")
);

const ThemeSettingsButtons = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettingsButtons")
);

const ThemeSettingsIcons = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettingsIcons")
);

const TripList = React.lazy(() =>
    import("./Components/Menu/MaterialTripList/TripList")
);

const TripListInfo = React.lazy(() =>
    import("./Components/Menu/MaterialTripList/TripListInfo")
);

const CrmUserList = React.lazy(() =>
    import("./Components/Menu/Crm/CrmUserList/CrmUserList")
);

const CrmDemandList = React.lazy(() =>
    import("./Components/Menu/Crm/CrmDemandList/CrmDemandList")
);

const CrmDemandDetail = React.lazy(() =>
    import("./Components/Menu/Crm/CrmDemandDetail/CrmDemandDetail")
);

const CrmUserDetail = React.lazy(() =>
    import("./Components/Menu/Crm/CrmUserDetail/CrmUserDetail")
);

//---- AgencyMarging ----//
const AgencyMargin = React.lazy(() =>
    //import("./Components/Menu/AgencyMargin/AgencyMargin")
    import("./Components/Menu/AgencyMargin/MarginList")
);

//---- AgencyUserManagement ----//
const AgencyUserManagement = React.lazy(() =>
    import("./Components/Menu/AgencyUserManagement/AgencyUserTable")
);

//---- AgencyCustomerManagement ----//
const AgencyCustomerManagement = React.lazy(() =>
    import("./Components/Menu/AgencyCustomerManagement/AgencyCustomerManagement")
);

//---- Help ----//
const Help = React.lazy(() =>
    import("./Components/Menu/Help/TripListVideo")
);

//---- Settings ----//
const Settings = React.lazy(() =>
    import("./Components/Menu/Settings/Settings")
);

//---- Transfers ----//
const Transfers = React.lazy(() =>
    import("./Components/Transfers/Transfers")
);
const StepTransfers = React.lazy(() =>
    import("./Components/Transfers/StepTransfers")
);
const CityTransfers = React.lazy(() =>
    import("./Components/Transfers/CityTransfers")
);

const AgencyOfflineMargin = React.lazy(() =>
    import("./Components/AgencyOfflineMargin/AgencyOfflineMargin")
);

const CmsSettings = React.lazy(() =>
    import("./Components/Menu/Settings/CmsSettings")
);

const ThemeSettingsList = React.lazy(() =>
    import("./Components/Menu/Settings/theme/themeSettingsList")
);

const CircuitSteps = React.lazy(() =>
    import("./Components/pages/circuitSteps")
);

const CircuitStepsParameters = React.lazy(() =>
    import("./Components/pages/circuitStepsTripParameters")
);

const CircuitStepsAccommodation = React.lazy(() =>
    import("./Components/pages/circuitStepsAccommodation")
);

const CircuitStepsProducts = React.lazy(() =>
    import("./Components/pages/circuitStepsProducts")
);

const CircuitStepsOptionalProducts = React.lazy(() =>
    import("./Components/pages/circuitStepsOptionalProducts")
);

const CircuitStepsInsurances = React.lazy(() =>
    import("./Components/pages/circuitStepsInsurances")
);

const CircuitBookingTravelers = React.lazy(() =>
    import("./Components/pages/circuitBookingTravelers")
);

const CircuitStepsBookingCartRecap = React.lazy(() =>
    import("./Components/pages/circuitStepsBookingCartRecap")
);

const CircuitCartSteps = React.lazy(() =>
    import("./Components/pages/circuitCartSteps")
);

const CircuitCartStepsBookingCartRecap = React.lazy(() =>
    import("./Components/pages/circuitCartStepsBookingCartRecap")
);

const CircuitCartStepsTravelers = React.lazy(() =>
    import("./Components/pages/circuitCartStepsTravelers")
);

const CircuitCartStepsBooking = React.lazy(() =>
    import("./Components/pages/circuitCartStepsBooking")
);

const CircuitCartStepsAccommodation = React.lazy(() =>
    import("./Components/pages/circuitCartStepsAccommodation")
);

const CircuitCartStepsProducts = React.lazy(() =>
    import("./Components/pages/circuitCartStepsProducts")
);

const CircuitCartStepsOptionalProducts = React.lazy(() =>
    import("./Components/pages/circuitCartStepsOptionalProducts")
);

const CircuitCartStepsFlights = React.lazy(() =>
    import("./Components/pages/circuitCartStepsFlights")
);

const CircuitCartStepsInsurances = React.lazy(() =>
    import("./Components/pages/circuitCartStepsInsurances")
);

const Destinations = React.lazy(() =>
    import("./Components/Home/MaterialHome")
);

const Booklets = React.lazy(() =>
    import("./Components/pages/booklets")
);

const OurContacts = React.lazy(() =>
    import("./Components/pages/ourContacts")
);

const BetweenUs = React.lazy(() =>
    import("./Components/pages/betweenUs")
);

const LegalMentions = React.lazy(() =>
    import("./Components/pages/legalMentions")
);

const Us = React.lazy(() =>
    import("./Components/pages/us")
);

const Insurance = React.lazy(() =>
    import("./Components/pages/insurance")
);

const StarsOfRoyalPalm = React.lazy(() =>
    import("./Components/pages/starsOfRoyalPalm")
);

const AgentsVacations = React.lazy(() =>
    import("./Components/pages/agentsVacations")
);

const Insurances = React.lazy(() =>
    import("./Components/CartMaterial/insurances")
);

const Flyers = React.lazy(() =>
    import("./Components/pages/flyers")
);

const Travelers = React.lazy(() =>
    import("./Components/CartMaterial/travelers")
);

const PageBuilder = React.lazy(() =>
    import("./Components/Menu/Settings/page-builder/pageBuilder")
);

const PageBuilderViewPage = React.lazy(() =>
    import("./Components/Menu/Settings/page-builder/pageBuilderViewPage")
);

const PageBuilderPreview = React.lazy(() =>
    import("./Components/Menu/Settings/page-builder/pageBuilderPreview")
);

const GlobalUtils = React.lazy(() =>
    import("./Components/Common/globalUtils")
);

const CircuitB2bParameters = React.lazy(() =>
    import("./Components/pages/circuitB2bTripParameters")
);

const CircuitB2bSteps = React.lazy(() =>
    import("./Components/pages/circuitB2bSteps")
);

const CircuitB2bAccommodationBasedOnSource = React.lazy(() =>
    import("./Components/pages/circuitB2bAccommodationBasedOnSource")
);

const CircuitB2bPois = React.lazy(() =>
    import("./Components/pages/circuitB2bPois")
);

const CircuitB2bTransfers = React.lazy(() =>
    import("./Components/pages/circuitB2bTransfers")
);

const CircuitB2bCars = React.lazy(() =>
    import("./Components/pages/circuitB2bCars")
);

const CircuitB2bProducts = React.lazy(() =>
    import("./Components/pages/circuitB2bProducts")
);

const CircuitB2bOptionalProducts = React.lazy(() =>
    import("./Components/pages/circuitB2bOptionalProducts")
);

const CircuitB2bInsurances = React.lazy(() =>
    import("./Components/pages/circuitB2bInsurances")
);

const CircuitB2bBookingCartRecap = React.lazy(() =>
    import("./Components/pages/circuitB2bBookingCartRecap")
);

const PagesList = React.lazy(() =>
    import("./Components/Menu/Settings/pages/pagesList")
);

const BoRedirection = React.lazy(() =>
    import("./Components/Common/boRedirection")
);

const TripListDocumentPage = React.lazy(() =>
    import("./Components/Menu/MaterialTripList/TripListDocumentPage")
);

const MaterialLoginPage = React.lazy(() =>
    import("./Components/Home/MaterialLoginPage")
);

// if (
//     window.location.hostname.split('.')[2] === 'com' &&
//     window.location.hostname !== 'demo.facilitatrip.com' &&
//     !window.location.host.includes('localhost')
// ) {
//     Sentry.init({
//         dsn: "https://6416f007833e40b8ad27b4947cb330e0@o161232.ingest.sentry.io/1227144",
//         integrations: [new BrowserTracing()],
//
//         //We recommend adjusting this value in production, or using tracesSampler
//         //for finer control
//         tracesSampleRate: 1.0
//     });
// }

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="*" Component={GlobalUtils}>
            <Route
                path={"access/:url_name/:trip_id/:trip_version/:trip_token"}
                Component={withTrip(Outlet)}
            />
            <Route path={"agency-price-edit/:trip_id/:trip_version/:trip_token/:token"} Component={AgencyOfflineMargin} />
            <Route
                path={"home/:url_name?"}
                Component={
                    withProfile(
                        identifier !== 'visiteurs' ? Home : VisiteursHome
                    )
                }
            />
            <Route
                path={"bo/redirect"}
                Component={
                    withProfile(BoRedirection)
                }
            />
            <Route
                path={":url_name"}
                Component={
                    withProfile(Outlet)
                }
            >
                <Route path="destinations" Component={Destinations} />
                <Route path={"reset-password"} Component={identifier !== 'visiteurs' ? Home : VisiteursHome} />
                <Route
                    path={"destination-product/:desti_id"}
                    element={
                        <RequireAuthRoute>
                            <DestinationProducts />
                        </RequireAuthRoute>
                    }
                />
                <Route path={"no-destination"} Component={NoDestinationProducts} />
                <Route path={"booklets"} Component={Booklets} />
                <Route
                    path={"our-contacts"}
                    element={
                        <RequireAuthRoute>
                            <OurContacts />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"between-us"}
                    element={
                        <RequireAuthRoute>
                            <BetweenUs />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"legal-mentions"}
                    element={
                        <RequireAuthRoute>
                            <LegalMentions />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"us"}
                    element={
                        <RequireAuthRoute>
                            <Us />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"insurance"}
                    element={
                        <RequireAuthRoute>
                            <Insurance />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"stars-of-royal-palm"}
                    element={
                        <RequireAuthRoute>
                            <StarsOfRoyalPalm />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"agents-vacations"}
                    element={
                        <RequireAuthRoute>
                            <AgentsVacations />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"flyers"}
                    element={
                        <RequireAuthRoute>
                            <Flyers />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"packages-details/:itinerary_type_name/:itinerary_type_id"}
                    element={
                        <RequireAuthRoute>
                            <PackagesDetails />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"circuits-details/:itinerary_type_name/:itinerary_type_id/:version?"}
                    element={
                        <RequireAuthRoute>
                            <CircuitsDetails />
                        </RequireAuthRoute>
                    }
                />
                <Route
                    path={"itinerary-type-details/:itinerary_type_name/:itinerary_type_id"}
                    element={
                        <RequireAuthRoute>
                            <ItineraryTypeDetails />
                        </RequireAuthRoute>
                    }
                />
                <Route path={"summary-quotation/payment/:trip_id/:trip_id_version/:trip_token"} Component={SummaryPayment} handle={{ show_map_calendar: false }} />
                <Route path={"summary-quotation/:trip_id/:trip_id_version/:trip_token"} Component={SummaryQuotation} handle={{ show_map_calendar: false }} />
                <Route path={"summary-inscription/:trip_id/:trip_id_version/:trip_token"} Component={SummaryInscription} handle={{ show_map_calendar: false }} />
                <Route path={"summary-documents/:trip_id/:trip_id_version/:trip_token"} Component={SummaryDocuments} handle={{ show_map_calendar: false }} />
                <Route path={"summary-contact/:trip_id/:trip_id_version/:trip_token"} Component={SummaryContact} handle={{ show_map_calendar: false }} />
                <Route path={"summary-quotation/payment/success/:trip_id/:trip_id_version/:trip_token"} Component={SummaryPaymentSuccess} handle={{ show_map_calendar: false }} />
                <Route path={"summary-quotation/payment/error/:trip_id/:trip_id_version/:trip_token"} Component={SummaryPaymentError} handle={{ show_map_calendar: false }} />
                <Route path={"provider-website/:trip_id/:trip_id_version/:trip_token/:product_type/:product_id"} Component={ProviderWebsite} handle={{ show_map_calendar: false }} />
                <Route path={"provider-quotation/provider/:providerId/trip/:tripId/version/:tripVersion/stack/:stackNumber/token/:tripToken"} Component={ProviderQuotation} handle={{ show_map_calendar: false }} />
                <Route path={"provider-booking/provider/:providerId/trip/:tripId/version/:tripVersion/token/:tripToken"} Component={ProviderBooking} handle={{ show_map_calendar: false }} />
                <Route
                    path={"provider-cancel/:trip_id/:trip_id_version/:trip_token/:product_type/:product_id"}
                    element={
                        <RequireAuthRoute>
                            <ProviderCancel />
                        </RequireAuthRoute>
                    }
                    handle={{ show_map_calendar: false }}
                />
                <Route path={"apps"} Component={Base} >
                    <Route index element={<Navigate to="itinerary" replace />} />
                    {
                        //<Route path={ "steps" } Component={ AppsSteps } show_map_calendar={ true } type={ 0 }/>
                    }
                    <Route path={"itinerary"} Component={Itinerary} handle={{ show_map_calendar: true, type: 0, timeline: false }} />
                    <Route path={"packages"} Component={Itinerary} handle={{ show_map_calendar: true, type: 0, timeline: false }} />
                    <Route path={"cruises"} Component={Itinerary} handle={{ show_map_calendar: true, type: 0, timeline: false }} />
                    <Route path={"flight-groups"} Component={FlightGroups} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route
                        path={"flight-search/:use_cache"}
                        element={
                            <CheckFlightRoute>
                                <FlightSearch
                                    isCms
                                />
                            </CheckFlightRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route
                        path={"selected-flight"}
                        element={
                            <CheckFlightRoute>
                                <SelectedFlight />
                            </CheckFlightRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route path={"accommodation"} Component={AccommodationList} handle={{ show_map_calendar: true, type: 1, timeline: true }} />
                    <Route path={"accommodation-card/:id"} Component={MaterialAccommodationCard} handle={{ show_map_calendar: false, timeline: false, type: 1 }} />
                    <Route path={"poi"} Component={PoiList} handle={{ show_map_calendar: true, type: 2, timeline: true }} />
                    <Route path={"poi-card/:id"} Component={PoiCard} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route
                        path={"cart"}
                        Component={
                            withOnLeave(Cart)(() => {
                                store.dispatch(resetCircuit());
                                store.dispatch(setSeeProductsMargins(false));
                            })
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route path={"insurances"} Component={Insurances} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route path={"travelers"} Component={Travelers} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route path={"cart-quotation"} Component={CartQuotation} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route path={"booking"} Component={Booking} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route
                        path={"cars-search"}
                        element={
                            <CheckDepartureReturnRoute>
                                <CarsSearch />
                            </CheckDepartureReturnRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route path={"material-cars-search"} Component={MaterialCarsSearch} handle={{ show_map_calendar: false, timeline: false }} name={""} />
                    <Route
                        path={"cars-search-one-step"}
                        element={
                            <CheckDepartureReturnRoute>
                                <CarsSearchOneStep />
                            </CheckDepartureReturnRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route
                        path={"cars-step"}
                        element={
                            <CheckDepartureReturnRoute>
                                <CarsStep />
                            </CheckDepartureReturnRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route
                        path={"cars-results/:id_step"}
                        element={
                            <CheckDepartureReturnRoute>
                                <CarsResults />
                            </CheckDepartureReturnRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route
                        path={"cars-add-to-cart/:id_step"}
                        element={
                            <CheckDepartureReturnRoute>
                                <CarsAddToCart />
                            </CheckDepartureReturnRoute>
                        }
                        handle={{ show_map_calendar: false, timeline: false }}
                    />
                    <Route path={"transfers"} Component={Transfers} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route path={"step-transfers"} Component={StepTransfers} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route path={"city-transfers"} Component={CityTransfers} handle={{ show_map_calendar: false, timeline: false }} />
                    <Route
                        path="circuit-steps"
                        Component={
                            withOnLeave(
                                withOnEnter(CircuitSteps)((navigate, searchParams) => {
                                    if (
                                        !searchParams.get('circuit') ||
                                        !searchParams.get('version') ||
                                        !searchParams.get('variant')
                                    ) {
                                        const circuit = store.getState().page.circuit.package.data;
                                        const url = localStorage.getItem('circuit-steps-last-circuit');
                                        if (!circuit && url) {
                                            const parsedUrl = new URL(url);
                                            navigate(
                                                {
                                                    pathname: parsedUrl.pathname,
                                                },
                                                { replace: true }
                                            );
                                        }
                                    }
                                })
                            )(() => {
                                if (!window.location.pathname.includes('/login')) {
                                    store.dispatch(resetCircuit());
                                }
                            })
                        }
                        handle={{
                            show_map_calendar: false,
                            timeline: false,
                            disabledLoader: true,
                            disabledHeader: true
                        }}
                    >
                        <Route
                            path="parameters"
                            Component={CircuitStepsParameters}
                        />
                        <Route
                            path={"flight-search/:use_cache"}
                            Component={(props) => (
                                <FlightSearch
                                    {...props}
                                    contentOnly
                                    isCircuitPrecart
                                />
                            )}
                            handle={{ show_map_calendar: false, timeline: false }}
                        />
                        <Route
                            path={"selected-flight"}
                            element={
                                <CheckFlightRoute>
                                    <SelectedFlight
                                        isCircuitPrecart
                                    />
                                </CheckFlightRoute>
                            }
                            handle={{ show_map_calendar: false, timeline: false }}
                        />
                        <Route
                            path="accommodation"
                            Component={CircuitStepsAccommodation}
                        />
                        <Route path="options">
                            <Route index element={<Navigate to="included" replace />} />
                            <Route
                                path="included"
                                Component={CircuitStepsProducts}
                            />
                            <Route
                                path="optional"
                                Component={CircuitStepsOptionalProducts}
                            />
                        </Route>
                        <Route
                            path="insurance"
                            Component={CircuitStepsInsurances}
                        />
                        <Route path="booking">
                            <Route index element={<Navigate to="travelers" replace />} />
                            <Route path="travelers" Component={CircuitBookingTravelers} />
                            <Route
                                path="cart-recap"
                                Component={
                                    withOnLeave(CircuitStepsBookingCartRecap)(() => {
                                        store.dispatch(setSeeProductsMargins(false));
                                    })
                                }
                            />
                        </Route>
                    </Route>
                    <Route
                        path="circuit-b2b-steps"
                        Component={
                            withOnLeave(
                                withOnEnter(CircuitB2bSteps)((navigate, searchParams) => {
                                    if (
                                        !searchParams.get('circuit') ||
                                        !searchParams.get('version') ||
                                        !searchParams.get('variant')
                                    ) {
                                        const circuit = store.getState().page.circuit.package.data;
                                        const url = localStorage.getItem('circuit-steps-last-circuit');
                                        if (!circuit && url) {
                                            const parsedUrl = new URL(url);
                                            navigate(
                                                {
                                                    pathname: parsedUrl.pathname,
                                                },
                                                { replace: true }
                                            );
                                        }
                                    }
                                })
                            )(() => {
                                if (!window.location.pathname.includes('/login')) {
                                    store.dispatch(resetCircuit());
                                }
                            })
                        }
                        handle={{
                            show_map_calendar: false,
                            timeline: false,
                            disabledLoader: true,
                            disabledHeader: true
                        }}
                    >
                        <Route
                            path="parameters"
                            Component={CircuitB2bParameters}
                        />
                        <Route
                            path={"flight-search/:use_cache"}
                            Component={(props) => (
                                <FlightSearch
                                    {...props}
                                    contentOnly
                                    isCircuitPrecart
                                />
                            )}
                            handle={{ show_map_calendar: false, timeline: false }}
                        />
                        <Route
                            path="accommodation"
                            Component={CircuitB2bAccommodationBasedOnSource}
                        />
                        <Route
                            path="pois"
                            Component={CircuitB2bPois}
                        />
                        <Route
                            path="transfers"
                            Component={CircuitB2bTransfers}
                        />
                        <Route
                            path="cars"
                            Component={CircuitB2bCars}
                        />
                        <Route path="options">
                            <Route index element={<Navigate to="included" replace />} />
                            <Route
                                path="included"
                                Component={CircuitB2bProducts}
                            />
                            <Route
                                path="optional"
                                Component={CircuitB2bOptionalProducts}
                            />
                        </Route>
                        <Route
                            path="insurance"
                            Component={CircuitB2bInsurances}
                        />
                        <Route path="booking">
                            <Route index element={<Navigate to="travelers" replace />} />
                            <Route path="travelers" Component={CircuitBookingTravelers} />
                            <Route path="cart-recap" Component={CircuitB2bBookingCartRecap} />
                        </Route>
                    </Route>
                    <Route
                        path="circuit-cart-steps"
                        Component={CircuitCartSteps}
                        handle={{
                            show_map_calendar: false,
                            timeline: false,
                            disabledLoader: true,
                            disabledHeader: true,
                        }}
                    >
                        <Route
                            path="booking"
                            Component={CircuitCartStepsBooking}
                        >
                            <Route index element={<Navigate to="cart-recap" replace />} />
                            <Route path="travelers" Component={CircuitCartStepsTravelers} />
                            <Route path="cart-recap" Component={CircuitCartStepsBookingCartRecap} />
                        </Route>
                        <Route path="accommodation" Component={CircuitCartStepsAccommodation} />
                        <Route path="insurance" Component={CircuitCartStepsInsurances} />
                        <Route path="flight" Component={CircuitCartStepsFlights} />
                        <Route path="options">
                            <Route index element={<Navigate to="included" replace />} />
                            <Route path="included" Component={CircuitCartStepsProducts} />
                            <Route path="optional" Component={CircuitCartStepsOptionalProducts} />
                        </Route>
                    </Route>
                </Route>
                <Route path="page-builder/:pageId" Component={PageBuilder} />
                <Route path="preview/:pageId" Component={PageBuilderPreview} />
                <Route path={"content/settings"} Component={CmsSettings}>
                    <Route index element={<Navigate to="themes" replace />} />
                    <Route path="themes" Component={ThemeSettingsList} />
                    <Route path="themes/colors/:themeId" Component={ThemeSettingsColors} />
                    <Route path="themes/inputs/:themeId" Component={ThemeSettingsInputs} />
                    <Route path="themes/typography/:themeId" Component={ThemeSettingsTypography} />
                    <Route path="themes/buttons/:themeId" Component={ThemeSettingsButtons} />
                    <Route path="themes/icons/:themeId" Component={ThemeSettingsIcons} />
                    <Route path="pages" Component={PagesList} />
                </Route>
                <Route
                    path={"menu"}
                    element={
                        <RequireAuthRoute>
                            <BaseMenu />
                        </RequireAuthRoute>
                    }
                    handle={{ timeline: false }}
                >
                    <Route index element={<Navigate to="profile" replace />} />
                    <Route path={"profile"} Component={Profile} />
                    <Route path={"documents"} Component={TripListDocumentPage} />
                    <Route path={"agency-profile"} Component={AgencyProfile} />
                    <Route path={"salespersons"} Component={SalesPersons} />
                    <Route path={"trip-list"} Component={TripList} handle={{ timeline: false, disabledHeader: true }} />
                    <Route path={"trip-info/:trip_id"} Component={TripListInfo} />
                    <Route path={"crm"} Component={CrmUserList} />
                    <Route path={"crm-user/:user_id"} Component={CrmUserDetail} />
                    <Route path={"crm-demands"} Component={CrmDemandList} />
                    <Route path={"crm-demand/:demand_id"} Component={CrmDemandDetail} />
                    <Route path={"agency-margin"} Component={AgencyMargin} />
                    <Route path={"agency-user-management"} Component={AgencyUserManagement} />
                    <Route path={"agency-customer-management"} Component={AgencyCustomerManagement} />
                    <Route path={"help"} Component={Help} />
                    <Route path={"settings"} Component={Settings} />
                </Route>
            </Route>
            <Route
                path="*"
                element={
                    <RequireAuthRoute>
                        <PageBuilderViewPage />
                    </RequireAuthRoute>
                }
            />
        </Route>
    )
);

async function enableMocking() {
    const { worker } = await import('./mocks/browser');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start()
}

enableMocking().then(() => {
    const root = createRoot(document.getElementById("app"));
    root.render(
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback="loading">
                    <ThemeProvider>
                        <CssBaseline>
                            <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }} disableWindowBlurListener={true} action={key => CloseSnackbar(key)}>
                                <RouterProvider router={router} />
                            </SnackbarProvider>
                        </CssBaseline>
                    </ThemeProvider>
                </Suspense>
            </I18nextProvider>
        </Provider>
    );
});
