import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router";

type Callback = (
    navigate: ReturnType<typeof useNavigate>,
    searchParams: ReturnType<typeof useSearchParams>[0],
) => void;

export function withOnEnter<T extends React.ElementType>(Component: T): (onEnterCallback: Callback) => React.FC {
    return (onEnterCallback) => {
        const ResultComponent: React.FC<Record<string, unknown>> = (props) => {
            const location = useLocation();
            const navigate = useNavigate();
            const [searchParams] = useSearchParams();

            useEffect(() => {
                onEnterCallback(navigate, searchParams);
            }, []);

            return (
                <Component {...props as any} location={location} />
            );
        }

        ResultComponent.displayName = `withOnEnter(${ResultComponent.displayName ?? ResultComponent.name})`;

        return ResultComponent;
    };
}
