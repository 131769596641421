import React, { type JSX } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailTemplateVisualEditorNumberPicker } from "./mailTemplateVisualEditorNumberPicker";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";

type Props = {
    width: number,
    onChangeWidth: (value: number) => void
}

export function MailTemplateVisualEditorBorderWidthOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-border-width')}</Typography>
            <MailTemplateVisualEditorNumberPicker
                measureUnit="px"
                value={props.width}
                onChange={props.onChangeWidth}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
