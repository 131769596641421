import React, { type JSX } from "react";

export type MailTemplateVisualEditorProps = {
    instanceId: string,
    placeholder?: string,
    locale?: number,
    forceSelectedState?: boolean,
    customActions?: {
        [blockType: string]: (
            props: {
                instanceId: string,
                blockId: number
            }
        ) => JSX.Element
    },
    onContentClick?: {
        [blockType: string]: (
            context: {
                isBlockSelected: boolean,
                instanceId: string,
                blockId: number,
                blockOptions: unknown
            }
        ) => void
    },
    isIdle?: boolean,
    disableBlocksEdit?: boolean,
    disablePreview?: boolean,
    disableHeader?: boolean,
    disableAutoCreation?: boolean,
    disableEmptyIndicator?: boolean,
    disableHoverInteraction?: boolean,
    enableLeftDrag?: boolean,
    useCustomDndContext?: boolean
}

export const MailTemplateVisualEditorContext = React.createContext<MailTemplateVisualEditorProps>({
    instanceId: ''
});
