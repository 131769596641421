import React, { useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router";
import GetCookie from "../Functions/GetCookie";
import SetCookie from "../Functions/SetCookie";

export function withTrip<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>): React.FC<P> {
    const Result = (props: React.ComponentProps<typeof Component>) => {
        const location = useLocation();
        const params = useParams();
        const [searchParams] = useSearchParams();

        useEffect(() => {
            let user_token = GetCookie("token");
            let id_user = GetCookie("id_user");
            let client_user = GetCookie("client_user");
            //Delete the cookie
            let cookies = document.cookie.split("; ");
            for (let c = 0; c < cookies.length; c++) {
                let d = window.location.hostname.split(".");
                while (d.length > 0) {
                    let cookieBase = encodeURIComponent(cookies[c]?.split(";")[0]?.split("=")[0] ?? '') + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                    let p = location.pathname.split("/");
                    document.cookie = cookieBase + "/";
                    while (p.length > 0) {
                        document.cookie = cookieBase + p.join("/");
                        p.pop();
                    }
                    d.shift();
                }
            }
            SetCookie("trip_id", params.trip_id ?? '', 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("trip_id_version", params.trip_version ?? '', 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("trip_token", params.trip_token ?? '', 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("token", user_token ?? '', 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("id_user", id_user ?? '', 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
            SetCookie("client_user", client_user ?? '', 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));

            if (searchParams.get('redirect') === 'cart') {
                window.location.href = `/${params.url_name}/apps/cart`;
            } else {
                window.location.href = `/${params.url_name}/apps/itinerary`;
            }
        }, []);

        return (
            <Component {...props} />
        );
    }

    Result.displayName = `withTrip(${Component.displayName ?? Component.name})`;

    return Result;
}