import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonGroup, IconButton, Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

export type Alignment = 'left' | 'center' | 'right'

type Props = {
    alignment: Alignment,
    onChangeAlignment: (alignment: Alignment) => void
}

export function MailTemplateVisualEditorAlignmentOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        (<MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-alignment')}</Typography>
            <Box>
                <ButtonGroup variant="contained" sx={{ padding: 1 }}>
                    <IconButton
                        sx={{ backgroundColor: props.alignment === 'left' ? '#ccc' : 'inherit' }}
                        onClick={() => props.onChangeAlignment('left')}
                        size="large">
                        <FormatAlignLeftIcon />
                    </IconButton>
                    <IconButton
                        sx={{ backgroundColor: props.alignment === 'center' ? '#ccc' : 'inherit' }}
                        onClick={() => props.onChangeAlignment('center')}
                        size="large">
                        <FormatAlignCenterIcon />
                    </IconButton>
                    <IconButton
                        sx={{ backgroundColor: props.alignment === 'right' ? '#ccc' : 'inherit' }}
                        onClick={() => props.onChangeAlignment('right')}
                        size="large">
                        <FormatAlignRightIcon />
                    </IconButton>
                </ButtonGroup>
            </Box>
        </MailTemplateVisualEditorOptionsSection>)
    );
}
