import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DynamicPage } from "../../Menu/Settings/page-builder/hooks/getDynamicPages";

type State = {
    headerHeight: number,
    openBlocksList: boolean,
    openLayers: boolean,
    openPageParameters: boolean,
    screen: 'DESKTOP' | 'TABLET' | 'PHONE',
    pages: DynamicPage[] | null,
    pageParameters: {
        name: string,
        url: string,
        parent: DynamicPage | null
    },
    leftColumnWidth: number
}

const initialState: State = {
    headerHeight: 0,
    openBlocksList: true,
    openLayers: false,
    openPageParameters: false,
    screen: 'DESKTOP',
    pages: null,
    pageParameters: {
        name: '',
        url: '',
        parent: null
    },
    leftColumnWidth: 275
}

const slice = createSlice({
    name: 'pageBuilder',
    initialState,
    reducers: {
        addNewPage: (
            state,
            action: PayloadAction<{ page: DynamicPage }>
        ) => {
            state.pages?.push(action.payload.page);
        },
        deletePage: (
            state,
            action: PayloadAction<number>
        ) => {
            state.pages = state.pages?.filter((item) => {
                return item.id !== action.payload;
            }) ?? null;
        },
        closeBlocksList: (
            state
        ) => {
            state.openBlocksList = false;
        },
        closeLayers: (
            state
        ) => {
            state.openLayers = false;
        },
        closePageParameters: (
            state
        ) => {
            state.openPageParameters = false;
        },
        openBlocksList: (
            state
        ) => {
            state.openBlocksList = true;
        },
        openLayers: (
            state
        ) => {
            state.openLayers = true;
        },
        openPageParameters: (
            state
        ) => {
            state.openPageParameters = true;
        },
        replacePage: (
            state,
            action: PayloadAction<{ page: DynamicPage }>
        ) => {
            state.pages = state.pages?.map((item) => {
                if (item.id === action.payload.page.id) {
                    return action.payload.page;
                }
                return item;
            }) ?? null;
        },
        setHeaderHeight: (
            state,
            action: PayloadAction<State['headerHeight']>
        ) => {
            state.headerHeight = action.payload
        },
        setLeftColumnWidth: (
            state,
            action: PayloadAction<{diff: number}>
        ) => {
            const newSize = state.leftColumnWidth + action.payload.diff;

            if (newSize > window.screen.width / 2) {
                state.leftColumnWidth = window.screen.width / 2;
            } else if (newSize < 200) {
                state.leftColumnWidth = 200;
            } else {
                state.leftColumnWidth = newSize;
            }
        },
        setPages: (
            state,
            action: PayloadAction<State['pages']>
        ) => {
            state.pages = action.payload
        },
        setPageParameter: <K extends keyof State['pageParameters']>(
            state: State,
            action: PayloadAction<{
                key: K,
                value: State['pageParameters'][K]
            }>
        ) => {
            state.pageParameters[action.payload.key] = action.payload.value;
        },
        setScreen: (
            state,
            action: PayloadAction<State['screen']>
        ) => {
            state.screen = action.payload
        },
        toggleBlocksList: (
            state
        ) => {
            state.openBlocksList = !state.openBlocksList;
        },
    }
});

export default slice;

export const {
    addNewPage,
    deletePage,
    closeBlocksList,
    closeLayers,
    closePageParameters,
    openBlocksList,
    openLayers,
    openPageParameters,
    replacePage,
    setHeaderHeight,
    setLeftColumnWidth,
    setPages,
    setScreen,
    toggleBlocksList
} = slice.actions;

export const setPageParameter = slice.actions.setPageParameter as <
    K extends keyof State['pageParameters'],
>(payload: {
    key: K,
    value: State['pageParameters'][K],
}) => PayloadAction<{
    key: K,
    value: State['pageParameters'][K],
}>;
