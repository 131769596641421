declare let API_HREF: string;
//Dependencies
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, FC, ReactElement, type JSX } from 'react';
//Core & Lab
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinearProgress from '@mui/material/LinearProgress';
import TablePagination from '@mui/material/TablePagination';
//Components
import PictureModalSearch from './pictureModalSearch';
import PictureModalListActions from './pictureModalListActions';
import CheckBeforeRequest from '../../../Common/CheckBeforeRequest';
//Actions
import { setList, setPage, setLimit, setRunning } from './redux/actions';
//Types
import { AppState } from '../../../../Reducers/Reducers';
import { Picture } from './objects/picture';

type Props = {
    onClickPicture?: (picture: Picture) => void,
    actions?: (picture: Picture) => JSX.Element | null,
    filter?: boolean
}

let request_timeout: NodeJS.Timeout;

const PictureModalList: FC<Props> = (props): ReactElement<any> => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const medium = useMediaQuery(theme.breakpoints.down('lg'));
    const large = useMediaQuery(theme.breakpoints.down('xl'));
    const extra_large = useMediaQuery(theme.breakpoints.down('xl'));
    const count = useSelector((state: AppState) => state.picture.count);
    const filter_types = useSelector((state: AppState) => state.picture.filter_types);
    const list = useSelector((state: AppState) => state.picture.list);
    const limit = useSelector((state: AppState) => state.picture.limit);
    const page = useSelector((state: AppState) => state.picture.page);
    const search = useSelector((state: AppState) => state.picture.search);
    const running = useSelector((state: AppState) => state.picture.running);
    const refresh = useSelector((state: AppState) => state.picture.refresh);

    const onLimitChange = (event: { target: { value: any; }; }) => {
        dispatch(setLimit(event.target.value));
    };
    const onChangePage = (event: any, page: any) => {
        dispatch(setPage(page));
    };
    const onClickPicture = (picture: Picture) => {
        if (props.onClickPicture) {
            props.onClickPicture(picture);
        }
    };

    const runRequest = () => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/pictures/`,
                headers,
                params: {
                    limit: limit,
                    offset: limit * page,
                    search: search !== '' ? search : undefined,
                    type__in: filter_types.join(',')
                }
            }).then((response) => {
                dispatch(setList(response.data));
            }).catch((error) => {
                console.log('error : ', error);
                dispatch(setRunning(false));
            });
        }
    };
    useEffect(() => {
        dispatch(setRunning(true));
        clearTimeout(request_timeout);
        request_timeout = setTimeout(runRequest, 500);
    }, [limit, page, search, filter_types, refresh]);
    return (
        <>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 }>
                    <PictureModalSearch/>
                </Grid>
                { running && <Grid item xs={ 12 }><LinearProgress/></Grid> }
                {
                    list !== null && list.map((picture, picture_index) => (
                        <Grid key={ `picture-modal-picture-${picture_index}` } item xs={ medium ? 12 : large ? 6 : extra_large ? 4 : 3 }>
                            <Card
                                onClick={() => onClickPicture(picture)}
                                sx={{ cursor: props.onClickPicture ? 'pointer' : 'inherit' }}
                            >
                                <CardMedia component={ 'img' } alt={ '' } height={ 200 } image={ picture.url !== null ? picture.url : '' }/>
                                <CardContent>
                                    <Typography>{ picture.name }</Typography>
                                </CardContent>
                                <CardActions>
                                    {
                                        props.actions ?
                                            props.actions(picture) :
                                            <PictureModalListActions picture={ picture }/>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
            <TablePagination rowsPerPageOptions={[12, 24, 36, 48]} component={ 'div' } count={ count } rowsPerPage={ limit } page={ page } onPageChange={ onChangePage } onRowsPerPageChange={ onLimitChange }/>
        </>
    );
};

export default PictureModalList;
