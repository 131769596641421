import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

type Callback = (navigate: ReturnType<typeof useNavigate>) => void;

export function withOnLeave<T extends React.ElementType>(Component: T): (onLeaveCallback: Callback) => React.FC {
    return (onLeaveCallback) => {
        const ResultComponent: React.FC<Record<string, unknown>> = (props) => {
            const location = useLocation();
            const navigate = useNavigate();
    
            useEffect(() => {
                return () => {
                    onLeaveCallback(navigate);
                };
            }, []);
    
            return (
                <Component {...props as any} location={location} />
            );
        }
    
        ResultComponent.displayName = `withOnLeave(${ResultComponent.displayName ?? ResultComponent.name})`;

        return ResultComponent;
    };
}
