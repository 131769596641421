import React, { useMemo, useState, type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    ClickAwayListener,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    Divider,
    Tooltip
} from "@mui/material";
import {
    FormatAlignLeft,
    FormatAlignCenter,
    FormatAlignRight,
    FormatAlignJustify,
    FormatIndentDecrease,
    FormatIndentIncrease,
    AddLink,
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    StrikethroughS,
    PhotoLibrary,
    FormatListBulleted,
    FormatListNumbered,
    Palette,
    Undo,
    Redo
} from "@mui/icons-material";
import { Map } from 'immutable';
import clsx from "clsx";
import { EditorState, Modifier, RichUtils } from "draft-js";
import { RichEditorFontSizeControl } from "./richEditorFontSizeControl";
import { RichEditorTextTypeControl } from "./richEditorTextTypeControl";
import { RichEditorTranslateControl } from "./richEditorTranslateControl";
import { customColors } from "./customColors";
import decorator from "./decorator";
import { RichEditorStyleMap } from "./editor";
import { toggleCreate } from '../../picture/redux/actions';
import { AppState } from "../../../../../Reducers/Reducers";

export type Toolbar =
    'text-type' |
    'font-size' |
    'alignment' |
    'indent' |
    'color' |
    'link' |
    'format' |
    'image' |
    'lists' |
    'undo' |
    'translate'

export type EditorToolbarProps = {
    editorState: EditorState,
    setEditorState: any,
    hideToolbar?: boolean,
    sticky?: boolean,
    toolbars?: Toolbar[],
    centerToolbars?: boolean,
}

export const EditorDefaultActivatedToolbarControls: Toolbar[] = [
    'text-type',
    'alignment',
    'indent',
    'color',
    'link',
    'format',
    'image',
    'lists',
    'undo'
];

export function EditorToolbar(
    {
        editorState,
        setEditorState,
        hideToolbar,
        centerToolbars,
        sticky,
        toolbars = EditorDefaultActivatedToolbarControls
    }: EditorToolbarProps
): JSX.Element | null {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openPalette, setOpenPalette] = useState(false);
    const [link, setLink] = useState('');
    const [linkText, setLinkText] = useState('');
    const [openLink, setOpenLink] = useState(false);
    const currentBlock = useMemo(() => {
        const selection = editorState.getSelection();
        return editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey());
    }, [editorState]);
    const alignment = useMemo(() => {
        const alignment = currentBlock.getData().get('alignment');
        return !alignment || alignment.length === 0 || alignment === 'left' ?
            'left' :
            alignment as string;
    }, [currentBlock]);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const styles = {
        ...RichEditorStyleMap,
        ...(quotationCode ? customColors[quotationCode] : null)
    };
    const blockType = currentBlock.getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    const onTogglePalette = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setOpenPalette((open) => !open);
    };

    const onColor = (color: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setOpenPalette(false);
        setEditorState(RichUtils.toggleInlineStyle(editorState, `COLOR_${color}`));
    };

    const onToggleBlockType = (type: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleBlockType(editorState, type));
    };

    const onChangeAlignment = (alignment: 'left' | 'center' | 'right' | 'justify') => {
        let result = editorState;
        const selection = result.getSelection();
        const newState = Modifier.mergeBlockData(
            result.getCurrentContent(),
            selection,
            Map({ alignment })
        );
        result = EditorState.push(result, newState, 'change-block-data');
        setEditorState(result);
    };

    const onIndentDecrease = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const tabIndent = '    ';
        const currentState = editorState;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const selectedText = currentContentBlock.getText();
        if (selectedText.substring(0, 4) === tabIndent) {
            const blockSelection = selectionState.merge({
                anchorOffset: 0,
                focusOffset: selectedText.length
            });
            const nextState = Modifier.replaceText(currentContent, blockSelection, selectedText.substring(4));
            setEditorState(EditorState.push(currentState, nextState, 'insert-characters'));
        }
    };

    const onIndentIncrease = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const tabIndent = '    ';
        const currentState = editorState;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const selectedText = currentContentBlock.getText();
        const blockSelection = selectionState.merge({
            anchorOffset: 0,
            focusOffset: selectedText.length
        });
        const nextState = Modifier.replaceText(currentContent, blockSelection, tabIndent + selectedText);
        setEditorState(EditorState.push(currentState, nextState, 'insert-characters'));
    };

    const onClickAwayLink = () => {
        setOpenLink(false);
    };

    const onToggleLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const anchorKey = selection.getAnchorKey();
            const startOffset = selection.getStartOffset();
            const endOffset = selection.getEndOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const currentContentBlock = contentState.getBlockForKey(anchorKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            const selectedText = currentContentBlock.getText().slice(startOffset, endOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().link;
            }
            setLink(url);
            setLinkText(selectedText);
        }
        setOpenLink((state) => !state);
    };

    const onLinkChange = (event: { target: { value: any; }; }) => {
        setLink(event.target.value);
    };

    const onLinkTextChange = (event: { target: { value: any; }; }) => {
        setLinkText(event.target.value);
    };

    const onAddLink = () => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                link: link,
                text: linkText
            }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.replaceText(
            contentState,
            selection,
            linkText,
            editorState.getCurrentInlineStyle(),
            entityKey
        );
        const newEditorState = EditorState.createWithContent(textWithEntity, decorator);
        setOpenLink(true);
        setEditorState(newEditorState);
        setLink('');
        setLinkText('');
    };

    const onToggleInlineStyle = (style: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const onAddPicture = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        dispatch(toggleCreate());
    };

    const onUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };
    const onRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    if (hideToolbar) {
        return null;
    }

    return (
        (<Grid
            className={'ft-editorButtonContainer'}
            item
            container
            sx={{
                display: 'flex',
                justifyContent: centerToolbars ? 'center' : 'flex-start',
                position: sticky ? 'sticky' : 'static',
                top: 0
            }}
        >
            {
                toolbars.includes('text-type') &&
                <RichEditorTextTypeControl
                    editorState={editorState}
                    onChangeEditorState={setEditorState}
                />
            }
            {
                toolbars.includes('font-size') &&
                <RichEditorFontSizeControl
                    editorState={editorState}
                    setEditorState={setEditorState}
                />
            }
            {
                toolbars.includes('color') &&
                <ClickAwayListener onClickAway={() => setOpenPalette(false)}>
                    <div style={{ position: 'relative' }}>
                        <IconButton disableRipple onMouseDown={onTogglePalette} size="large">
                            <Palette />
                        </IconButton>
                        {
                            openPalette &&
                            <Grid
                                className={'ft-editorPaletteColorContainer'}
                                sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}
                                container
                                spacing={1}
                            >
                                <Grid item container spacing={1}>
                                    {
                                        Object.keys(styles).filter((key) => {
                                            return key.startsWith('COLOR');
                                        }).map((key) => {
                                            return (
                                                <Grid key={key} item>
                                                    <div
                                                        style={{ backgroundColor: styles[key]?.color }}
                                                        className={'ft-editorPaletteColor'}
                                                        onMouseDown={onColor(key.replace('COLOR_', ''))}
                                                    />
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        }
                    </div>
                </ClickAwayListener>
            }
            {
                toolbars.includes('alignment') &&
                <>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: alignment === 'left' })}
                        disableRipple
                        onMouseDown={() => onChangeAlignment('left')}
                        size="large">
                        <FormatAlignLeft />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: alignment === 'center' })}
                        disableRipple
                        onMouseDown={() => onChangeAlignment('center')}
                        size="large">
                        <FormatAlignCenter />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: alignment === 'right' })}
                        disableRipple
                        onMouseDown={() => onChangeAlignment('right')}
                        size="large">
                        <FormatAlignRight />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: alignment === 'justify' })}
                        disableRipple
                        onMouseDown={() => onChangeAlignment('justify')}
                        size="large">
                        <FormatAlignJustify />
                    </IconButton>
                </>
            }
            {
                toolbars.includes('indent') &&
                <>
                    <IconButton disableRipple onMouseDown={onIndentDecrease} size="large">
                        <FormatIndentDecrease />
                    </IconButton>
                    <IconButton disableRipple onMouseDown={onIndentIncrease} size="large">
                        <FormatIndentIncrease />
                    </IconButton>
                </>
            }
            {
                toolbars.includes('link') &&
                <ClickAwayListener onClickAway={onClickAwayLink}>
                    <div style={{ position: 'relative' }}>
                        <IconButton disableRipple onMouseDown={onToggleLink} size="large">
                            <AddLink />
                        </IconButton>
                        {
                            openLink &&
                            <div className={'ft-editorLinkContainer'}>
                                <Grid container spacing={2} justifyContent={'flex-end'}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth required variant={'outlined'}>
                                            <InputLabel>{t('shared.link')}</InputLabel>
                                            <OutlinedInput
                                                type={'text'}
                                                onChange={onLinkChange}
                                                label={t('shared.link')}
                                                value={link}
                                                autoComplete={'off'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth required variant={'outlined'}>
                                            <InputLabel>{t('shared.link-text')}</InputLabel>
                                            <OutlinedInput
                                                type={'text'}
                                                onChange={onLinkTextChange}
                                                label={t('shared.link-text')}
                                                value={linkText}
                                                autoComplete={'off'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={onAddLink}>{t('shared.validate')}</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                </ClickAwayListener>
            }
            {
                toolbars.includes('format') &&
                <>
                    <Divider orientation={'vertical'} flexItem />
                    <IconButton
                        className={clsx({ ['ft-editorActive']: currentStyle.has('BOLD') })}
                        disableRipple
                        onMouseDown={onToggleInlineStyle('BOLD')}
                        size="large">
                        <FormatBold />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: currentStyle.has('ITALIC') })}
                        disableRipple
                        onMouseDown={onToggleInlineStyle('ITALIC')}
                        size="large">
                        <FormatItalic />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: currentStyle.has('UNDERLINE') })}
                        disableRipple
                        onMouseDown={onToggleInlineStyle('UNDERLINE')}
                        size="large">
                        <FormatUnderlined />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: currentStyle.has('STRIKETHROUGH') })}
                        disableRipple
                        onMouseDown={onToggleInlineStyle('STRIKETHROUGH')}
                        size="large">
                        <StrikethroughS />
                    </IconButton>
                </>
            }
            {
                toolbars.includes('image') &&
                <>
                    <Divider orientation={'vertical'} flexItem />
                    <IconButton disableRipple onMouseDown={onAddPicture} size="large">
                        <PhotoLibrary />
                    </IconButton>
                </>
            }
            {
                toolbars.includes('lists') &&
                <>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: blockType === 'unordered-list-item' })}
                        disableRipple
                        onMouseDown={onToggleBlockType('unordered-list-item')}
                        size="large">
                        <FormatListBulleted />
                    </IconButton>
                    <IconButton
                        className={clsx({ ['ft-editorActive']: blockType === 'ordered-list-item' })}
                        disableRipple
                        onMouseDown={onToggleBlockType('ordered-list-item')}
                        size="large">
                        <FormatListNumbered />
                    </IconButton>
                </>
            }
            {
                (toolbars.includes('translate') || toolbars.includes('undo')) &&
                <Divider orientation="vertical" flexItem />
            }
            {
                toolbars.includes('translate') &&
                <RichEditorTranslateControl
                    editorState={editorState}
                    setEditorState={setEditorState}
                />
            }
            {
                toolbars.includes('undo') &&
                <>
                    <Tooltip title={`${t('shared.undo')} (Ctrl + z)`}>
                        <IconButton onMouseDown={onUndo} disableRipple size="large">
                            <Undo />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`${t('shared.redo')} (Ctrl + y)`}>
                        <IconButton onMouseDown={onRedo} disableRipple size="large">
                            <Redo />
                        </IconButton>
                    </Tooltip>
                </>
            }
        </Grid>)
    );
}
