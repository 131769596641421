import React, { useRef, useState, type JSX } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    CircularProgress,
    ClickAwayListener,
    IconButton,
    Menu,
    MenuItem,
    Tooltip
} from "@mui/material";
import { GTranslate } from "@mui/icons-material";
import axios from "axios";
import { EditorState } from "draft-js";
import { renderRichEditorContentHTML } from "./renderRichEditorContentHtml";
import { restoreRichEditorStateFromHtml } from "./restoreRichEditorStateFromHtml";
import { RichEditorStyleMap } from "./editor";
import { customColors } from "./customColors";
import { AppState, Key } from "../../../../../Reducers/Reducers";

type Props = {
    editorState: EditorState,
    setEditorState: (state: EditorState) => void,
}

export function RichEditorTranslateControl(props: Props): JSX.Element {
    const { t } = useTranslation();
    const localesList = useSelector((state: AppState) => state.user.locales);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const anchorEl = useRef(null);
    const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
    const googleKey = config.keys?.find((item) => item.identifier === 'google_api')?.value;
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onTranslate = async (targetLocale: number) => {
        const target = localesList.find((item) => {
            return item.id === targetLocale;
        })?.language_code;
        if (googleKey && target) {
            const styles = {
                ...RichEditorStyleMap,
                ...(quotationCode ? customColors[quotationCode] : null)
            };
            const value = renderRichEditorContentHTML(
                styles,
                props.editorState.getCurrentContent()
            );
            const translatedTexts = await translateTexts({
                googleKey,
                target,
                texts: [value]
            });
            props.setEditorState(
                restoreRichEditorStateFromHtml(
                    styles,
                    translatedTexts[0] ?? value
                )
            );
            setLoading(false);
        }
    };

    return (
        (<div>
            <ClickAwayListener onClickAway={onClose}>
                <Tooltip title={t<string>('shared.translate')}>
                    <IconButton
                        ref={anchorEl}
                        onClick={onToggle}
                        sx={{ marginLeft: 1 }}
                        disableRipple
                        size="large">
                        {
                            loading ?
                                <CircularProgress size="20px" /> :
                                <GTranslate />
                        }
                    </IconButton>
                </Tooltip>
            </ClickAwayListener>
            <Menu
                anchorEl={anchorEl.current}
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{ maxHeight: 250 }}
                hideBackdrop
                disableAutoFocus
                disableAutoFocusItem
                disableEnforceFocus
                disableRestoreFocus
                disablePortal
            >
                {
                    localesList.map((locale) => (
                        <MenuItem key={locale.id} onClick={() => onTranslate(locale.id)}>
                            {locale.full_name}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>)
    );
}

type TranslateOptions = {
    googleKey: string,
    texts: string[],
    target: string
}

async function translateTexts(options: TranslateOptions): Promise<string[]> {
    const response = await axios.post<{
        data: {
            translations: {
                translatedText: string
            }[]
        }
    }>(
        'https://translation.googleapis.com/language/translate/v2',
        {
            q: options.texts,
            target: options.target
        },
        {
            params: {
                key: options.googleKey
            }
        }
    );
    return response.data.data.translations.map((item) => {
        return item.translatedText;
    });
}
