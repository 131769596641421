declare let API_HREF: string;
//Dependencies
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, ReactElement, useContext, useEffect } from 'react';
//Core & Lab
import Button from '@mui/material/Button';
import CheckBeforeRequest from '../../../Common/CheckBeforeRequest';
import { useShowError } from '../../../Utils/showError';
//Actions
import { addSelection, deleteFile, setAdding, addCustomPictures, updateProgress } from './redux/actions';
//Utils
import { PictureContext } from './utils/pictureContext';
//Types
import { AppState } from '../../../../Reducers/Reducers';

type Props = {
    onClose: () => void
}

const PictureModalCreate: FC<Props> = (props): ReactElement<any> => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const adding = useSelector((state: AppState) => state.picture.adding);
    const create_alt = useSelector((state: AppState) => state.picture.create_alt);
    const create_credit = useSelector((state: AppState) => state.picture.create_credit);
    const create_description = useSelector((state: AppState) => state.picture.create_description);
    const create_name = useSelector((state: AppState) => state.picture.create_name);
    const files = useSelector((state: AppState) => state.picture.files);
    const selection = useSelector((state: AppState) => state.picture.selection);
    const pictureContext = useContext(PictureContext);
    const showError = useShowError();

    const onCreate = () => {
        dispatch(setAdding(true));
        files.map((file, file_index) => {
            const request = new FormData();
            if (create_name) {
                request.append('name', create_name);
            } else {
                request.append('name', t<string>('shared.picture'));
            }

            if (create_alt) {
                request.append('alt', create_alt);
            }

            if (create_credit) {
                request.append('credits', create_credit);
            }

            if (create_description) {
                request.append('description', create_description);
            }

            request.append('image', file);
            const { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: 'POST',
                    url: `${API_HREF}client/${window.id_owner}/pictures/`,
                    headers,
                    params: {
                        type: pictureContext.pictureTypeOnUpload ?? 'PICTURE'
                    },
                    data: request,
                    onUploadProgress: (progressEvent) => {
                        dispatch(updateProgress(progressEvent.loaded / progressEvent.total * 100));
                    }
                }).then((response) => {
                    dispatch(addSelection(response.data, file.name));
                }).catch((error) => {
                    dispatch(deleteFile(file_index));
                    showError(error);
                });
            }
        });
    };
    useEffect(() => {
        if (adding && files.length === 0) {
            //if (manual_accommodation !== null) {
            dispatch(addCustomPictures(selection));
            if (pictureContext.onChangePictures) {
                pictureContext.onChangePictures(selection);
            }
            props.onClose();
            //}
        }
    }, [adding, files]);
    return (
        <PictureContext.Consumer>
            {({ disabled }) => (
                <Button
                    onClick={ onCreate }
                    disabled={
                        disabled ||
                        files.length === 0 && selection.length === 0
                    }
                >
                    { t<string>('shared.validate-selection') }
                </Button>
            )}
        </PictureContext.Consumer>
    );
};

export default PictureModalCreate;
