import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import axios, { isAxiosError } from "axios";
import { store } from "../../../Store";
import { ToggleAccommodationRecommended } from "../../../Actions/Accommodation";
import { SetCurrencyList } from "../../../Actions/Base";
import { SetLocales } from "../../../Actions/User";
import CheckBeforeRequest from "../CheckBeforeRequest";
import { useTranslation } from "react-i18next";

export function withInstance<P extends {}>(Component: React.ComponentType<P>): React.FC<P> {
    const Result = (props: React.ComponentProps<typeof Component>) => {
        const dispatch = useDispatch();
        const { i18n } = useTranslation();
        const { enqueueSnackbar } = useSnackbar();
        const [finishedRequestsCount, setFinishedRequestsCount] = useState(0);

        useEffect(() => {
            (async () => {
                // let domain_name = window.location.host.replace("www.", "");
                // let customer_identifier = "";
                // if (url_name !== undefined && url_name !== null) {
                //     customer_identifier = url_name;
                // } else {
                //     customer_identifier = domain_name.split('.')[0];
                // }
                // if (window.location.host === "localhost:3000") {
                //     domain_name = "dev.facilitatrip.com";
                //     if (url_name === undefined || url_name === null) {
                //         customer_identifier = "dev";
                //     }
                // }
                const is_bonogo = false;
                let domain_name = "dev.facilitatrip.com";
                let customer_identifier = "dev";
                // console.log("API_HREF : ", API_HREF);
                // API_HREF = window.location.host.replace("-cms", "").replace(".com", ".fr");
                // console.log("window.location.host : ", window.location.host.replace("-cms", "").replace(".com", ".fr"));
                // console.log("API_HREF : ", API_HREF);
                // let domain_name = "dev.facilitatrip.com";
                // let customer_identifier = "beachcomber";

                try {
                    const response = await axios.get(
                        `${API_HREF}instances/?customer_identifier=${customer_identifier}&domain_name=${domain_name}`
                    );
                    const data = response.data;
                    console.log(data);
                    if (is_bonogo) {
                        let link = (document.querySelector("link[rel*='icon']") || document.createElement('link')) as HTMLLinkElement;
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        link.href = "/Img/cms/bonogo-favicon.png";
                        document.getElementsByTagName('head')[0]?.appendChild(link);
                    } else if (data.favicon !== undefined && data.favicon !== null && data.favicon.thumbnail_little !== undefined && data.favicon.url !== undefined) {
                        let link = (document.querySelector("link[rel*='icon']") || document.createElement('link')) as HTMLLinkElement;
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        link.href = (data.favicon.thumbnail_little !== null ? data.favicon.thumbnail_little : data.favicon.url);
                        document.getElementsByTagName('head')[0]?.appendChild(link);
                    }
                    if (data.analytics !== null) {
                        ReactGA.initialize(data.analytics);
                        ReactGA.pageview(window.location.pathname + window.location.search);
                    }
                    localStorage.setItem("config", JSON.stringify(data));
                    if (data.quotation_code === "amerigo" && !store.getState().accommodation.recommended) {
                        dispatch(ToggleAccommodationRecommended());
                    }
                    window.id_owner = data.instance_id;
                    window.id_buyer = data.client_id;
                    window.url_name = customer_identifier;
                } catch (error) {
                    console.error(error);
                    if (isAxiosError(error) && error.status === 404) {
                        enqueueSnackbar(
                            "L'instance que vous essayez d'accéder n'existe pas, veuillez vérifier votre url d'accès",
                            { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' } }
                        );
                    }
                } finally {
                    setFinishedRequestsCount((state) => state + 1);
                }
            })();
        }, []);

        useEffect(() => {
            (async () => {
                const { headers } = CheckBeforeRequest();
                if (store.getState().base.currency_list.length === 0) {
                    try {
                        const response = await axios.get(
                            `${API_HREF}currencies/?limit=200`,
                            { headers }
                        );
                        const data = response.data;
                        if (data.results.length > 0) {
                            dispatch(SetCurrencyList(data.results));
                        }

                    } catch (error) {
                        console.error(error);
                    } finally {
                        setFinishedRequestsCount((state) => state + 1);
                    }
                }
            })();
        }, []);

        useEffect(() => {
            (async () => {
                const { headers } = CheckBeforeRequest();
                try {
                    const response = await axios.get(
                        `${API_HREF}locales/`,
                        { headers }
                    );
                    const data = response.data;
                    const default_locale = data.results.find((el: any) => {
                        return el.language_code === 'fr';
                    });
                    const main_locale = data.results.find((el: any) => {
                        return el.language_code === i18n.language;
                    });
                    dispatch(SetLocales(data.results));
                    dispatch({ type: "USER_SET_MAIN_LOCALE", payload: main_locale ? main_locale : default_locale });

                } catch (error) {
                    console.error(error);
                } finally {
                    setFinishedRequestsCount((state) => state + 1);
                }
            })();
        }, []);

        if (finishedRequestsCount !== 3) {
            return null;
        }

        return (
            <Component {...props} />
        );
    }

    Result.displayName = `withInstance(${Component.displayName ?? Component.name})`;

    return Result;
}