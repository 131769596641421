import React, { useState, type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Stack, styled, Typography } from "@mui/material";
import PictureModalCreateForm from "../picture/pictureModalCreateForm";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { Picture } from "../picture/objects/picture";
import PhotoIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
    title?: string,
    url: string | null,
    onChangeUrl: (url: string | null) => void
}

export function MailTemplateVisualEditorImageOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const onAddPicture = () => {
        setOpen(true);
    };

    const onChangePictures = (pictures: Picture[]) => {
        const picture = pictures[0];
        if (picture && picture.url) {
            props.onChangeUrl(picture.url);
        }
    };

    const onDelete = () => {
        props.onChangeUrl(null);
    };

    return (<>
        {
            open &&
            <PictureModalCreateForm
                onChangePictures={onChangePictures}
                onClose={() => setOpen(false)}
                singleFile
            />
        }
        <MailTemplateVisualEditorOptionsSection>
            <Typography>
                {
                    props.title ?? t<string>('shared.mail-template-visual-editor-image-file')
                }
            </Typography>
            {
                props.url && props.url.startsWith('http') &&
                <ImagePreviewContainer>
                    <div className="image-option-preview-wrapper">
                        <ImagePreviewBackground
                            sx={{
                                backgroundImage: `url(${props.url})`
                            }}
                            className="image-option-background"
                        >
                            <img width="150px" height="auto" src={props.url} />
                        </ImagePreviewBackground>
                        <ImagePreviewDeleteButton
                            onClick={onDelete}
                            className="image-option-delete"
                        >
                            <DeleteIcon />
                        </ImagePreviewDeleteButton>
                    </div>
                </ImagePreviewContainer>
            }
            {
                props.url && !props.url.startsWith('http') &&
                <Typography variant="caption" color="text.secondary">
                    {t<string>('shared.mail-template-visual-editor-image-variable')}
                </Typography>
            }
            <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                    onClick={onAddPicture}
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    size="large">
                    <PhotoIcon />
                </IconButton>
                <Button onClick={onAddPicture} component="label">
                    {t<string>('shared.add-picture')}
                </Button>
            </Stack>
        </MailTemplateVisualEditorOptionsSection>
    </>);
}

const ImagePreviewContainer = styled('div')(() => ({
    '& > .image-option-preview-wrapper': {
        "position": 'relative',
        "display": 'inline-block',
        "lineHeight": 0,
        '& .image-option-delete': {
            display: 'none'
        },
        '&:hover .image-option-delete': {
            display: 'inline-flex'
        },
        '&:hover .image-option-background': {
            filter: 'blur(2px)'
        }
    }
}));

const ImagePreviewBackground = styled(Box)((props) => ({
    "backgroundSize": 'contain',
    "transition": props.theme.transitions.create(
        'filter',
        {
            duration: 500,
            easing: props.theme.transitions.easing.easeInOut
        }
    ),
    '& img': {
        visibility: 'hidden'
    }
}));

const ImagePreviewDeleteButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff'
}));
