// Dependencies
import React from 'react';
import { useSnackbar } from 'notistack';
// Core
import IconButton from '@mui/material/IconButton';
// Icons
import Close from '@mui/icons-material/Close';

const CloseSnackbar = ({key}) => {
    const { closeSnackbar } = useSnackbar();
    return (
        (<IconButton onClick={() => closeSnackbar(key)} size="large">
            <Close style={{color: 'white'}}/>
        </IconButton>)
    );
};

export default CloseSnackbar;