import React from "react";

type Style = {
    [index: string]: React.CSSProperties
}

export const customColors: { [index: string]: Style } = {
    cercledesvoyages: {
        COLOR_BF8A68: {
            color: '#BF8A68'
        }
    },
    beachcomber: {
        COLOR_B4A064: {
            color: '#B4A064',
        },
        COLOR_003366: {
            color: '#003366'
        }
    }
};
