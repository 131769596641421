import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorNumberPicker } from "./mailTemplateVisualEditorNumberPicker";

type Props = {
    size?: number,
    onChangeSize: (value: number) => void,
    min?: number,
    max?: number
}

export function MailTemplateVisualEditorSizeOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-size')}</Typography>
            <MailTemplateVisualEditorNumberPicker
                measureUnit="px"
                value={props.size ?? 0}
                onChange={props.onChangeSize}
                min={props.min}
                max={props.max}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
